






import {Component, Vue, Prop} from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import {RevenueReportService} from "@/views/dashboard/RevenueReportService";
import {CategoryType} from "@/models/category/CategoryType";
import {CustomerGroupService} from "@/views/customer-group/CustomerGroupService";
import {TreeModel} from "@/components/tree/TreeModel";
import moment from 'moment';
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import CategorySelect from "@/components/category/CategorySelect.vue";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";


@Component({
    components: {
        apexchart: VueApexCharts,
        CategorySelect,
        CustomerGroupTreeDropdown,
        UserSuggestion
    }
})
export default class RevenueReport extends Vue {
    @Prop({default: ''}) title: string;
    @Prop({default: 0}) filterBy: number;
    @Prop({default: () => []}) data: string;

    series = [];

    chartOptions = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'flat'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [],
        },
        yaxis: {
            title: {
                text: 'Doanh thu'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return ""
                }
            }
        }
    };

    updateData(result: any) {
        this.updateChartSeries(result);
        this.updateChartOptions(result.data);
    }

    private updateChartSeries(result: any) {
        const data = result.data;
        const filterRanges = result.filterRanges;
        const seriesData = filterRanges.map((filterRange: any) => {
            const groupByName = groupBy(data, 'name');
            const groupData: any = [];
            for (let key in groupByName) {
                const group = groupByName[key];
                const dataByRange = group.filter((item: any) => {
                    return this.filterBy === 3 ? item.year === filterRange.year
                        : item.filterValue === filterRange.value && item.year === filterRange.year;
                });
                groupData.push(!dataByRange ? 0 : sumBy(dataByRange, 'totalAmount'))
            }
            return {
                name: this.getSerieName(this.filterBy === 3 ? filterRange.year : filterRange.value, filterRange.year),
                data: groupData
            }
        });
        const chart: any = this.$refs.revenueChart;
        chart.updateSeries(seriesData);
    }

    private updateChartOptions(data: any) {
        const groupByName = groupBy(data, 'name');
        const categoryNames: any = [];
        for (let key in groupByName) {
            categoryNames.push(key);
        }
        const chart: any = this.$refs.revenueChart;
        chart.updateOptions({
            xaxis: {
                categories: categoryNames,
            }
        });
    }

    private getSerieName(filterValue: number, year: number) {
        return `${this.filterBy === 0 ? 'Tuần' : this.filterBy === 1 ? 'Tháng' : this.filterBy === 2 ? 'Quý' : 'Năm'}
        ${filterValue} ${this.filterBy != null ? `Năm ${year}` : ''}`;
    }

}
